import request from '@/utils/request'


// 查询帮助问题列表
export function listHelp(query) {
  return request({
    url: '/help/adminHelp/list',
    method: 'get',
    params: query
  })
}

// 查询帮助问题分页
export function pageHelp(query) {
  return request({
    url: '/help/adminHelp/page',
    method: 'get',
    params: query
  })
}

// 查询帮助问题详细
export function getHelp(data) {
  return request({
    url: '/help/adminHelp/detail',
    method: 'get',
    params: data
  })
}

// 新增帮助问题
export function addHelp(data) {
  return request({
    url: '/help/adminHelp/add',
    method: 'post',
    data: data
  })
}

// 修改帮助问题
export function updateHelp(data) {
  return request({
    url: '/help/adminHelp/edit',
    method: 'post',
    data: data
  })
}

// 删除帮助问题
export function delHelp(data) {
  return request({
    url: '/help/adminHelp/delete',
    method: 'post',
    data: data
  })
}
