<template>
  <a-modal
    :title="formTitle"
    :width="800"
    :visible="open"
    @cancel="onClose"
    cancelText="关闭"
    @ok="submitForm">
    <!--    <a-divider orientation="left">-->
    <!--      <b>{{ formTitle }}</b>-->
    <!--    </a-divider>-->
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标题" prop="title" >
              <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor :editorValue="'editor'" v-model="form.content" placeholder="请输入内容" />
      </a-form-model-item>
      <!--      <div class="bottom-control">-->
      <!--        <a-space>-->
      <!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
      <!--            保存-->
      <!--          </a-button>-->
      <!--          <a-button type="dashed" @click="cancel">-->
      <!--            取消-->
      <!--          </a-button>-->
      <!--        </a-space>-->
      <!--      </div>-->
    </a-form-model>
  </a-modal>
</template>

<script>
import { getHelp, addHelp, updateHelp } from '@/api/help/help'
import Editor from '@/components/Editor'
import Vue from 'vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        title: null,

        content: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      toForm: {},
      toForm2: {},
      languageList: [],
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    // allLanguages() {
    //   getAction('/user/user-language/list', {}).then((res) => {
    //     this.languageList = res.data
    //   })
    // },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        title: null,
        content: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHelp({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.toForm = JSON.parse(this.form.title)
        this.toForm2 = JSON.parse(this.form.content)
      })
    },
    returnDispla() {
      debugger
      // title标题数据
      const data1 = this.toForm
      const data2 = this.toForm2
      var toForm = {}
      var toForm2 = {}
      this.languageList.forEach(languageData => {
        debugger
        const Value1 = data1[languageData.id]
        Vue.set(toForm, languageData.id, Value1)
        const Value2 = data2[languageData.id]
        Vue.set(toForm2, languageData.id, Value2)
      })
      this.form.title = JSON.stringify(toForm)
      this.form.content = JSON.stringify(toForm2)
    },
    /** 提交按钮 */
    submitForm: function () {
      this.returnDispla()
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHelp(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addHelp(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
